<template>
    <section class="surface">
        <div style="padding: 10px;">
            <span style="padding: 0 10px;">水表号 : {{ surfaceData.waterNum }}</span>
            <span style="padding: 0 10px;">当前水表读数 : {{ surfaceData.water }}</span>
            <span style="padding: 0 10px;">当前水表状态 : {{ surfaceData.waterNum ? waterState : '无水表' }}</span>
            <el-button type="primary" size="small" :underline="false" @click="updateNum('Water')">修改水表号</el-button>
            <!--<span style="padding: 0 10px;">
                <el-link type="primary" :underline="false" @click="getHydropowerClose(500502,'open')">水表开闸</el-link>
            </span>
            <span style="padding: 0 10px;">
                <el-link type="primary" :underline="false" @click="getHydropowerClose(500502,'close')">水表关闸</el-link>
            </span>-->
            <el-button type="primary" size="small" :underline="false" @click="addSurface">水电表抄表</el-button>
            <el-button type="primary" size="small" :underline="false" @click="replace('Water')">更换水表</el-button>
        </div>
        <div v-if="surfaceData.waterNum">
      <r-e-table ref="communityWaterTableRef" :columns="tableColumnWater" :dataRequest="getHydropowerList" :query="formSearch" :height="330"
        :row-style="rowWaterStyle">
        <el-table-column slot="toolbar" label="操作" width="100">
          <template slot-scope="{ row }">
            <div class="table-tools">
              <span class="table-btn" @click="detail(row, 0)">详情</span>
              <!--                      2024-11-6-陈守亮暂时注释-需求暂时需要注释水电编辑-->
<!--              <span class="table-btn" v-if="row.waterDataDevice !== 0 && row.updateStatus === 0"-->
<!--                    @click="edit(row, 0)">编辑</span>-->
            </div>
          </template>
        </el-table-column>
      </r-e-table>
    </div>

    <div v-else style="height: 200px;
    line-height: 200px;
    text-align: center;">
      <span>无数据</span>
    </div>

        <div style="padding: 10px;">
            <span style="padding: 0 10px;">电表号 : {{ surfaceData.electNum }}</span>
            <span style="padding: 0 10px;">当前电表读数 : {{ surfaceData.elect }}</span>
            <span style="padding: 0 10px;">当前电表状态 : {{ electState }}</span>
            <el-button type="primary" size="small" :underline="false" @click="updateNum('Elect')">修改电表号</el-button>
            <el-button type="primary" size="small" :underline="false" @click="getHydropowerClose(500501,'open')">电表供电
            </el-button>


            <el-button type="primary" size="small" :underline="false" @click="getHydropowerClose(500501,'close')">电表断电
            </el-button>
            <!-- <el-button type="primary" size="small" :underline="false" @click="replaceElect()">更换电表</el-button> -->
            <el-button type="primary" size="small" :underline="false" @click="replace('Elect')">更换电表</el-button>
            <el-button type="primary" size="small" :underline="false" @click="controlGateRecord()">控闸记录</el-button>
            <el-button type="primary" size="small" :underline="false" @click="meterReadingRecord()">抄表记录</el-button>
        </div>

        <r-e-table ref="communityElectTableRef" :columns="tableColumnElect" :dataRequest="getHydropowerList"
                   :query="formSearch" :height="330" :row-style="rowElectStyle">
            <el-table-column slot="toolbar" label="操作" width="100">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="detail(row,1)">详情</span>
<!--                      2024-11-6-陈守亮暂时注释-需求暂时需要注释水电编辑-->
<!--                        <span class="table-btn" v-if="row.electDataDevice !== 0 && row.updateStatus === 0" @click="edit(row,1)">编辑</span>-->
                    </div>
                </template>
            </el-table-column>
        </r-e-table>

        <dialog-update-hydropower-num ref="dialogUpdateHydropowerNum" :equipmentData="equipmentData"
                                      @getHydropower="getHydropower"/>

        <dialog-add-surface ref="dialogAddSurface" :equipmentData="equipmentData" @handleSearch="handleSearch"/>
        <dialog-add-surface-suite ref="dialogAddSurfaceSuite" :equipmentData="equipmentData" @handleSearch="handleSearch"/>

        <r-e-dialog title="详情" class="layer-contract-detail" :visible.sync="detailsDialogVisible" top="10vh"
                    width="1700px" @close="detailsDialogVisible=false">
            <bill-details ref="billDetails" :billData="billData"/>
        </r-e-dialog>

        <dialog-replace-hydropower ref="dialogReplaceHydropower" :equipmentData="equipmentData"
        @confirm="handleConfirm"/>
        <!-- // 2024/09/20 张晓瑜新增更换电表 -->
        <dialog-replace-electricity-meter ref="dialogReplaceElectricityMeter" :equipmentData="equipmentData"
        @confirm="handleConfirm"/>
        <dialog-hydropower-log-update ref="dialogHydropowerLogUpdate" :equipmentData="equipmentData"
                                      @getHydropower="getHydropower"/>
        <!-- 控闸记录 -->
        <dialocontrolgaterecord ref="opencontrolgaterecordref" :equipmentData="equipmentData"></dialocontrolgaterecord>
        <!-- 抄表记录 -->
        <dialoMeterReadingRecord ref="openMeterReadingRecord" :equipmentData="equipmentData"></dialoMeterReadingRecord>
    </section>
</template>

<script>
import {tableColumnWater, tableColumnElect} from "@/views/property-management/door-management/data";
import {getHydropowerList, getHydropowerClose, byHyUuid,getApartmentlMainUuidApi,hydropower} from "@/api/door-management";
import {MessageInfo, MessageError, MessageSuccess} from "@custom/message";
// import {getHouseDetailApi} from "@/api/house-resource";

export default {
    name: "surface",
    props: {
        equipmentData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            tableColumnWater,
            tableColumnElect,
            formSearch: {},
            surfaceData: {},
            electState: '',
            waterState: '',
            billData: null,
            detailsDialogVisible: false,
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
        };
    },
    components: {
        dialogUpdateHydropowerNum: () => import("./components/dialog-update-hydropower-num"),
        dialogAddSurface: () => import("./components/dialog-add-surface"),
        dialogAddSurfaceSuite: () => import("./components/dialog-add-surface-suite"),
        billDetails: () => import("@/views/bill-management/rent-withdrawal/components/billDetails"),
        dialogReplaceHydropower: () => import("./components/dialog-replace-hydropower"),
        dialogHydropowerLogUpdate: () => import("./components/dialog-hydropower-log-update"),
        dialocontrolgaterecord: () => import("./components/dialog-control-gate-record"),
        dialoMeterReadingRecord:()=>import("./components/dialog-meter-reading-record"),
        dialogReplaceElectricityMeter:()=>import("./components/dialog-replace-electricity-meter"),
    },
    mounted() {
    },
    created() {
        console.log('equipmentData',this.equipmentData);
        let {
            waterMeter, electricityMeter, electState, waterState, electNum, waterNum, electStatus, waterStatus,water,elect
        } = this.equipmentData;
        console.log('waterMeter',waterMeter);
        this.surfaceData.electricityMeter = electricityMeter;
        this.surfaceData.waterMeter = waterMeter;
        this.surfaceData.water = water;// 水表读数
        this.surfaceData.elect = elect;//电表读数
        this.setElectState(electState === null ? "失败" : (electState === 'close' ? "关闭" : "开启"));
        this.setWaterState(waterState === null ? "失败" : (waterState === 'close' ? "关闭" : "开启"));
        this.surfaceData.electState = electState;
        this.surfaceData.waterState = waterState;
        this.surfaceData.electNum = electNum;
        this.surfaceData.waterNum = waterNum;
        console.log('waterState',this.surfaceData.waterState);
    },
    methods: {
        updateNum(type) {
            this.$refs["dialogUpdateHydropowerNum"].openDialog(type);
        },

        getHydropower(data) {
            console.log('data',data);
            let {waterNum, waterMeter, electNum, electricityMeter} = data;
            this.surfaceData.waterNum = waterNum;
            this.surfaceData.waterMeter = waterMeter;
            this.surfaceData.electNum = electNum;
            this.surfaceData.electricityMeter = electricityMeter;
            this.handleSearch();
            this.$emit('handleSearch');
        },

       async addSurface() {
            const { apartmentUuid } = this.equipmentData;
            const loadingOptions = this.loadingOptions;
            const loading = this.$loading({...loadingOptions});
            await getApartmentlMainUuidApi(apartmentUuid).then(({ info: { mainUuid } }) => {
                console.log('mainUuid', mainUuid);
                // 判断是否为套房
                if(mainUuid){
                    //  -- 套房展示
                    this.$refs["dialogAddSurfaceSuite"].openDialog();
                }else{
                    this.$refs["dialogAddSurface"].openDialog();
                }
            });
        },

        handleSearch(isSearch = true) {
            // 搜索
            this.$refs["communityWaterTableRef"].getTableData(isSearch);
            this.$refs["communityElectTableRef"].getTableData(isSearch);
        },

        getHydropowerList(params) {
            params.uuid = this.equipmentData.uuid;
            return getHydropowerList(params);
        },

        getHydropowerClose(code, action) {
            let that = this;
            let {uuid} = this.equipmentData;
            let {waterNum, electNum} = this.surfaceData;
            const loadingOptions = that.loadingOptions;
            const loading = this.$loading({...loadingOptions});
            getHydropowerClose({uuid, code, num: code === 500502 ? waterNum : electNum, action}).then(res => {
                console.log('res',res);
                // let {valveStatus} = res.info;
                // console.log('valveStatus',valveStatus);
               that.gethydropower()
                MessageSuccess((code === 500502 ? "水表" : "电表") + (action === "open" ? "开启" : "关闭") + "成功");

                // if (code === 500502) that.setWaterState(valveStatus === "open" ? "开启" : "关闭");
                // else that.setElectState(valveStatus === "open" ? "开启" : "关闭");
                loading.close();
            }).catch(err => {
                console.log('err',err);
                that.gethydropower()
                MessageError((code === 500502 ? "水表" : "电表") + (action === "open" ? "开启" : "关闭") + "失败");
                loading.close();
            });
        },
      async  gethydropower(){
        let {uuid} = this.equipmentData;
           await hydropower(uuid).then(res => {
              console.log("ressss",res);
              const { electState, waterState} = res.info;
              this.setElectState(electState === null ? "失败" : (electState === 'close' ? "关闭" : "开启"));
              this.setWaterState(waterState === null ? "失败" : (waterState === 'close' ? "关闭" : "开启"));
            });
        },
        setElectState(state) {
            this.electState = state;
        },

        setWaterState(state) {
            this.waterState = state;
        },

        async detail(data, type) {
            const {uuid} = data;
            const loadingOptions = this.loadingOptions;
            const loading = this.$loading({...loadingOptions});
            const res = await byHyUuid({uuid, type}).catch(err => loading.close()).finally(() => loading.close());
            if (res) {
                const {info} = res;
                this.billData = info;
                this.detailsDialogVisible = true;
            } else MessageInfo("此记录没有账单");
        },

        replace(type) {
            this.$refs["dialogReplaceHydropower"].openDialog(type);
        },
        // 2024/09/20 张晓瑜新增更换电表
        replaceElect(){
            this.$refs["dialogReplaceElectricityMeter"].openDialog();
        },
        // 2024/04/18 张晓瑜调整颜色不透明度
        rowWaterStyle(row) {
            const {row: {updateStatus, waterDataDevice}} = row;
            if (waterDataDevice === 0) return {}
            if (updateStatus === 0) return {backgroundColor: 'rgba(255,69,0,0.2)'};
            if (updateStatus === 1) return {backgroundColor: 'rgba(30,144,255,0.2)'};
        },

        rowElectStyle(row) {
            const {row: {updateStatus, electDataDevice}} = row;
            if (electDataDevice === 0) return {}
            if (updateStatus === 0) return {backgroundColor: 'rgba(255,69,0,0.2)'};
            if (updateStatus === 1) return {backgroundColor: 'rgba(30,144,255,0.2)'};
        },

        edit(data, type) {
            this.$refs['dialogHydropowerLogUpdate'].openDialog(data, type);
        },
        handleConfirm(){
            this.handleSearch()
        this.$emit('handleSearch');
    },
    // refreshTables(){
    //     // this.$emit('handleSearch');
    //     this.handleSearch();
    //     this.$refs["communityElectTableRef"].getTableData();
    // }
    // 2024/07/23 张晓瑜新增控闸记录
    controlGateRecord(){
        const {uuid} = this.equipmentData;
        this.$refs["opencontrolgaterecordref"].openDialog(uuid);
      },
    // 2024/09/18 张晓瑜新增抄表记录
      meterReadingRecord(){
        const {uuid} = this.equipmentData;
        this.$refs["openMeterReadingRecord"].openDialog(uuid);
      }
    },
    computed: {}
}
</script>

<style lang="scss" scoped>
</style>
